
import TitleBlock from "../../components/TitleBlock";
import { useEffect, useState } from "react";
import OperPeriod from "../../components/TarifCompany/OperPeriod";
import { getOperPrice } from "../../Axios/calculationsYr";
import MapTarifs from "../../components/TarifCompany/MapTarifs";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

const Tarifs = () => {

  const [rozpValue, SetRozpValue] = useState(0);
  const [calcList, SetCalcList] = useState([]);
  const [lastmonth, SetLastMonth] = useState('');

  const currentYear = new Date().getFullYear();
  let yearList = [];
  for(let i = currentYear; i >= 2020; i--){
    yearList.push(i);
  }

  const monthList = [
    {id:'12', name:'Грудень'},
    {id:'11', name:'Листопад'},
    {id:'10', name:'Жовтень'},
    {id:'09', name:'Вересень'},
    {id:'08', name:'Серпень'},
    {id:'07', name:'Липень'},
    {id:'06', name:'Червень'},
    {id:'05', name:'Травень'},
    {id:'04', name:'Квітень'},
    {id:'03', name:'Березень'},
    {id:'02', name:'Лютий'},
    {id:'01', name:'Січень'},
  ];


    useEffect(() => {
        window.scrollTo(0,0);
        let active = true

        getOperPrice().then((response)=>{
          const all = response["hydra:member"];
          const [sortLastDate] = all.sort(function (a, b) {
            if (a.period > b.period) { return -1; }
            if (a.period < b.period) { return 1; }
             return 0; });
            
            if (active) {      
              SetLastMonth(sortLastDate.period);
            }   
        })

        return () => {
            active = false;
        };
        
      }, [])

        return (
           <>
           <div className="bg_img"></div>
           <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
             <TitleBlock title={'Встановлені тарифи НКРЕКП'} />
             </div>
             <Container>
             <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
              <Row>
                <Col md={12} className={'tarif__description'}>
                <div className='line__blue'></div>
                  <p>Ціна на електричну енергію (без ПДВ) включає такі складові:</p>
                  <ul><li>ціна закупівлі електричної енергії на ринку електричної енергії; 
                    <span>Даний тариф затверджується Національною комісією, що здійснює державне регулювання у сферах енергетики 
                      та комунальних послуг (НКРЕКП) та встановлюється однаковим на всю територію України</span></li>
                      <li>тариф на послуги оператора системи розподілу (ОСР); 
                        <span>Даний тариф затверджується НКРЕКП та встановлюється індивідуально для кожного ОСР</span></li>
                      <li>тариф на послуги постачальника.</li>
                  </ul>
                  <p>Усі встановлені тарифи затверджує НКРЕКП. В залежності від обраної комерційної пропозиції оплата за послуги Оператора систем_и розподілу 
                    може здійснюватись через постачальника або напряму. В цьому розділі ми надаємо інформацію про поточні тарифи.</p>
                </Col>
              </Row>
              </div>
             </Container>
             <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
             <TitleBlock subtitle={'Розрахувати тариф вашого ОСР та тариф з передачі електроенергії'} />
             </div>
             <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
             <MapTarifs rozpValue= {rozpValue} SetRozpValue = {SetRozpValue} SetCalcList = {SetCalcList}/>
             </div>
             <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100">
             <OperPeriod calcList={calcList} lastmonth={lastmonth} monthList = {monthList} yearList = {yearList}/>
             </div>
             {/* <SliderNews/> */}
          </>
     );
}

export default Tarifs