import { AxiosInstance } from "./axios";

export const getOperRozp = async ()=>{
    const calcList = await AxiosInstance.get('/api/oper_rozps?pagination=false');
    return calcList.data;
}

export const getOperRozpElement = async (id)=>{
    const calcList = await AxiosInstance.get(`/api/oper_rozps/${id}`);
    return calcList.data;
}


export const getOperPrice = async (id)=>{
    const calcList = await AxiosInstance.get(`/api/calculator_price_yurs?pagination=false${id !== undefined ? `&oper.id=${id}` : ''}`);
    return calcList.data;
}